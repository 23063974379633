import React, { useState, useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "animate.css";

function Techstack({ data }) {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="w-full px-4 sm:px-6 py-16 sm:py-8">
      <div
        ref={inViewRef}
        className={`flex sm:px-4 flex-col items-center justify-center ${
          inView
            ? "animate__animated animate__fadeInUp animate__slow"
            : "opacity-0 translate-y-10"
        }`}
      >
        <span className="titlecontent">
          {data && data.TechStackSection && data.TechStackSection.title}
          <span className="text-warning">.</span>
        </span>
      </div>
      <div
        className={`flex justify-center pb-3 ${
          inView
            ? "animate__animated animate__fadeInUp animate__slow"
            : "opacity-0 translate-y-10"
        }`}
      >
        <div className="subcontenttitle text-center">
          {data && data.TechStackSection && data.TechStackSection.subcontent1}
        </div>
      </div>
      <div
        className={`flex justify-center pb-4 ${
          inView
            ? "animate__animated animate__fadeInUp animate__slow"
            : "opacity-0 translate-y-10"
        }`}
      >
        <div className="col text-center">
          <div className="subcontenttitle p-0">
            {data && data.TechStackSection && data.TechStackSection.subcontent2}
          </div>
        </div>
      </div>
      <div
        className={`flex justify-center pb-4 ${
          inView
            ? "animate__animated animate__fadeInUp animate__slow"
            : "opacity-0 translate-y-10"
        }`}
      >
        <img
          src="/cover/techstack.png"
          class="rounded"
          alt="Cinque Terre"
          className="flex text-center w-[600px] sm:w-full"
        ></img>
      </div>
    </div>
  );
}

export default Techstack;
