import React, { useState, useEffect, useRef } from "react";
import { MdOutlineWeb, MdOutlineSettingsEthernet } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";
import { CgWebsite } from "react-icons/cg";
import { HiTemplate } from "react-icons/hi";
import { GiArtificialIntelligence } from "react-icons/gi";
import { FaFigma } from "react-icons/fa";
import { GrSystem } from "react-icons/gr";

const Service = ({ data }) => {
  const serviceItems = data?.ServiceSection?.card || [];
  const divRefs = useRef(serviceItems.map(() => React.createRef()));
  const [divInView, setDivInView] = useState(serviceItems.map(() => false));

  useEffect(() => {
    const handleScroll = () => {
      const screenHeight = window.innerHeight;

      divRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const { top, bottom } = ref.current.getBoundingClientRect();
          if (top < screenHeight && bottom >= 0) {
            setTimeout(() => {
              setDivInView((prevInView) => {
                const updatedInView = [...prevInView];
                updatedInView[index] = true;
                return updatedInView;
              });
            }, index * 200);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="w-full bg-[#f4f4f4] py-16 sm:py-8">
      <div className="">
        <div
          className={`flex sm:px-4 pb-3 flex-col items-center justify-center ${
            divInView.some((inView) => inView)
              ? "animate__animated animate__fadeInUp animate__slow"
              : "opacity-0 translate-y-10"
          }`}
        >
          <p className="titlecontent text-center pt-3">
            {data && data.ServiceSection && data.ServiceSection.title}
            <span className="text-warning">.</span>
          </p>
          <div className="subcontenttitle text-center p-0">
            {data && data.ServiceSection && data.ServiceSection.subcontent}
          </div>
        </div>
        <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-2 gap-4 px-24 sm:px-4 justify-center">
          {serviceItems.map((item, index) => (
            <div
              className={`${
                divInView[index]
                  ? "animate__animated animate__fadeInUp animate__slow"
                  : "opacity-0 translate-y-10"
              } w-full flex`}
              key={index}
              ref={divRefs.current[index]}
            >
              <div className="group gap-3 flex flex-col group-hover:icon-rotate h-full items-center text-center bg-white w-full p-4 hover:shadow-[0_0_60px_rgba(0,0,0,0.1)] rounded-[25px]">
                <div className="flex justify-center items-center">
                  <div className="flex p-3 justify-center text-center items-cemter icon-wrapper group-hover:animate-rotate-icon">
                    {index === 0 && <MdOutlineWeb size={36} />}
                    {index === 1 && <CiMobile3 size={36} />}
                    {index === 2 && <CgWebsite size={36} />}
                    {index === 3 && <HiTemplate size={36} />}
                    {index === 4 && <MdOutlineSettingsEthernet size={36} />}
                    {index === 5 && <GiArtificialIntelligence size={36} />}
                    {index === 6 && <GrSystem size={36} />}
                    {index === 7 && <FaFigma size={36} />}
                  </div>
                </div>
                <div className="flex flex-col items-center gap-1 justify-start">
                  <h3 className="text-gray-800 text-[20px] sm:text-[16px] font-bold group-hover:text-[#e55951] p-0 m-0">
                    {item.title}
                  </h3>
                  <p className="text-gray-700 font-normal text-[14px] p-0 m-0">
                    {item.des}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Service;
