import React, { useState, useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "animate.css";
import { Modal, Button } from "react-bootstrap";

function Footer({ data }) {
  const [showMapModal, setShowMapModal] = useState(false);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const handleCloseMapModal = () => setShowMapModal(false);
  const handleShowMapModal = () => setShowMapModal(true);

  return (
    <div
      className={`w-full px-16 sm:px-8 bg-gradient-to-r from-[#00093c] to-[#2d0b00]`}
    >
      <div ref={inViewRef} className="flex flex-col w-full pt-6">
        <div
          className={`w-full flex justify-between pb-4 ${
            inView
              ? "animate__animated animate__fadeInUp"
              : "opacity-0 translate-y-10"
          }`}
        >
          <div className="w-full flex justify-start">
            <img
              src="/Perfectcom-LOGOVWhite (1).png"
              className="w-20 h-auto object-contain"
              alt="Perfectcom Logo"
            />
          </div>
          <div className="w-full flex items-center gap-2 justify-end">
            <img
              src="/lineaddpfc.png"
              className="w-20 h-auto object-contain"
              alt="Perfectcom Logo"
            />
            <a
              href="https://lin.ee/KhwIenD"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                className="btn rounded-[20px] !bg-emerald-600 hover:!bg-emerald-700 hover:border-[#047857] border !border-[#059669] active rounded-pill px-3 m-0"
                data-mdb-ripple-color="dark"
              >
                <p className="font-bold p-0 m-0 text-white">@Perfectcom</p>
              </button>
            </a>
          </div>
        </div>
        <div className="w-full">
          <hr className="p-0 m-0" />
        </div>
        <div
          className={`grid grid-cols-3 sm:grid-cols-1 sm:gap-6 pt-4 pr-6 sm:pr-0 ${
            inView
              ? "animate__animated animate__fadeInUp"
              : "opacity-0 translate-y-10"
          }`}
        >
          <div className="w-full gap-2">
            <div className="flex flex-col">
              <div className="flex flex-col w-fit">
                <div className="footertitle w-full">
                  <h3 className="text-white p-0 m-0">Perfectcom</h3>
                </div>
                <div className="w-full pb-3">
                  <div className="bottom_line"></div>
                </div>
              </div>
              <div className="w-full">
                <p className="footersubtext p-0 m-0">
                  เราคือทีมมืออาชีพที่มุ่งมั่นในการพัฒนาซอฟต์แวร์ที่ตรงตามความต้องการของธุรกิจคุณ
                  ไม่ว่าคุณจะต้องการโปรแกรมใหม่ การปรับปรุงระบบ หรือโซลูชัน IT
                  แบบครบวงจร
                  เรามีความเชี่ยวชาญในการส่งมอบเทคโนโลยีทันสมัยที่ตอบโจทย์ความต้องการที่หลากหลายของคุณอย่างมีประสิทธิภาพ
                </p>
              </div>
            </div>
          </div>
          <div className="w-full gap-2 pl-16 sm:pl-0">
            <div className="flex flex-col">
              <div className="flex flex-col w-fit">
                <div className="footertitle w-full">
                  <h3 className="text-white p-0 m-0">Service</h3>
                </div>
                <div className="w-full pb-3">
                  <div className="bottom_line"></div>
                </div>
              </div>
            </div>
            <div className="w-full">
              <li className="footersubtext">เว็บแอปพลิเคชัน</li>
              <li className="footersubtext">แอปพลิเคชันมือถือ</li>
              <li className="footersubtext">LMS/E-Learning</li>
              <li className="footersubtext">เว็บเทมเพลต</li>
              <li className="footersubtext">Internet of Things (IoT)</li>
              <li className="footersubtext">ปัญญาประดิษฐ์ (AI)</li>
              <li className="footersubtext">โปรแกรมบริหารจัดการ (ERP)</li>
              <li className="footersubtext">UX/UI Design & Service</li>
            </div>
          </div>
          {/* <div className="w-full gap-2 pl-16 sm:pl-0">
            <div className="flex flex-col">
              <div className="flex flex-col w-fit">
                <div className="footertitle w-full">
                  <h3 className="text-white p-0 m-0">
                    {data.FooterElement.footer[0].title1}
                  </h3>
                </div>
                <div className="w-full pb-3">
                  <div className="bottom_line"></div>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-[12px]">
              <div className="footersubtext">
                <a
                  className="custom-link-style p-0 m-0"
                  href="https://www.facebook.com/pfcsolutions"
                  style={{ textDecoration: "none", color: "#fff" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.FooterElement.footer[0].social1}
                </a>
              </div>
              <div className="footersubtext">
                <a
                  className="custom-link-style p-0 m-0"
                  href="https://lin.ee/MqYiSFm"
                  style={{ textDecoration: "none", color: "#fff" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.FooterElement.footer[0].social2}
                </a>
              </div>
              <div className="footersubtext">
                <a
                  className="custom-link-style p-0 m-0"
                  href="https://www.linkedin.com/in/perfectcomputer-solution-576329285/"
                  style={{ textDecoration: "none", color: "#fff" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.FooterElement.footer[0].social3}
                </a>
              </div>
            </div>
          </div> */}
          <div className="w-full gap-2 md:pl-16">
            <div className="flex flex-col">
              <div className="flex flex-col w-fit">
                <div className="footertitle w-full">
                  <h3 className="text-white p-0 m-0">
                    {data.FooterElement.footer[1].title2}
                  </h3>
                </div>
                <div className="w-full pb-3">
                  <div className="bottom_line"></div>
                </div>
              </div>
              <div className="w-full flex flex-col gap-[12px]">
                <div className="footersubtext">
                  <a
                    className="custom-link-style"
                    style={{ textDecoration: "none", color: "#fff" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleShowMapModal}
                  >
                    {data.FooterElement.footer[1].address}
                  </a>
                </div>
                <div className="footersubtext">
                  <a
                    className="custom-link-style"
                    href={`mailto:${data.FooterElement.footer[1].email}`}
                    style={{ textDecoration: "none", color: "#fff" }}
                    rel="noopener noreferrer"
                  >
                    {data.FooterElement.footer[1].email}
                  </a>
                </div>
                <div className="footersubtext">
                  <a
                    className="custom-link-style"
                    href={`tel:${data.FooterElement.footer[1].phone}`}
                    style={{ textDecoration: "none", color: "#fff" }}
                    rel="noopener noreferrer"
                  >
                    {data.FooterElement.footer[1].phone}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={inViewRef}
          className={`w-full pt-4 flex flex-col ${
            inView
              ? "animate__animated animate__fadeInUp"
              : "opacity-0 translate-y-10"
          }`}
        >
          <hr className="p-0 m-0" />
          <p className="copyright text-center pt-4 text-white">
            {data.FooterElement.Reserved}
          </p>
        </div>
      </div>
      {/* Map Modal */}
      <Modal show={showMapModal} onHide={handleCloseMapModal}>
        <div className="flex text-center pt-5">
          <p className="titlemodal animate__animated animate__slideAndZoom">
            PERFECT COMPUTER SOLUTIONS
            <span className="text-warning">.</span>
          </p>
        </div>
        <Modal.Body>
          <iframe
            title="Perfectcom Map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31008.285422342018!2d100.662403!3d13.716289000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d605828f1641d%3A0x35d097c2ac2169b2!2sBIZ!5e0!3m2!1sth!2sth!4v1692636142822!5m2!1sth!2sth"
            width="100%"
            height="350"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseMapModal}>
            ปิดแผนที่
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Footer;
