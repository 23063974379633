import React, { useState, useEffect, useRef } from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";

function Certificate({ data }) {
  const [mobileScreen, setMobileScreen] = useState();
  const serviceItems = data?.CertificateSection?.certificate || [];
  const divRefs = useRef(serviceItems.map(() => React.createRef()));
  const [divInView, setDivInView] = useState(serviceItems.map(() => false));

  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    const handleResize = () => {
      setMobileScreen(window.innerWidth <= 992);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const screenHeight = window.innerHeight;

      divRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const { top, bottom } = ref.current.getBoundingClientRect();
          if (top < screenHeight && bottom >= 0) {
            setTimeout(() => {
              setDivInView((prevInView) => {
                const updatedInView = [...prevInView];
                updatedInView[index] = true;
                return updatedInView;
              });
            }, index * 200);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="w-full bg-[#f4f4f4] py-16 sm:py-8">
      <div
        className={`flex sm:px-4 md:px-8 flex-col items-center justify-center ${
          divInView.some((inView) => inView)
            ? "animate__animated animate__fadeInUp animate__slow"
            : "opacity-0 translate-y-10"
        }`}
      >
        <span className="titlecontent text-center">
          {data && data.CertificateSection && data.CertificateSection.title}
          <span className="text-warning">.</span>
        </span>
      </div>
      <div className="flex w-full justify-center pt-4">
        <div
          className={`flex gap-14 sm:gap-0 sm:px-2 w-full ${
            mobileScreen ? "flex w-full sm:h-fit" : "flex w-full"
          } items-start justify-center`}
        >
          {data &&
            data.CertificateSection &&
            data.CertificateSection.certificate.map((item, index) => (
              <div
                className={`flex flex-col sm:h-fit w-fit justify-center text-center items-center`}
              >
                <div
                  key={index}
                  ref={divRefs.current[index]}
                  className={`${
                    divInView[index]
                      ? "animate__animated animate__fadeInUp animate__slow"
                      : "opacity-0 translate-y-10"
                  } flex items-center sm:gap-4 sm:h-fit justify-center flex-col`}
                >
                  <img
                    src={item.image}
                    className="rounded w-full sm:px-12 pb-2 h-[300px] sm:h-min"
                    alt={item.title}
                    style={{
                      backgroundSize: "cover",
                      border: "14px",
                      objectFit: "contain",
                    }}
                  />
                  <p
                    className="p-0 m-0 font-bold text-gray-800"
                    style={{
                      fontSize: mobileScreen ? "16px" : "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.title}
                  </p>
                </div>
                {/* {index === 0 && (
                  <a
                    href={item.pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#000",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-outline-danger active rounded-pill px-3"
                      data-mdb-ripple-color="dark"
                    >
                      {data &&
                        data.CertificateSection &&
                        data.CertificateSection.button}
                    </button>
                  </a>
                )} */}
              </div>
            ))}
        </div>
      </div>
      <div
        ref={inViewRef}
        className={`flex px-6 flex-col justify-center ${
          inView
            ? "animate__animated animate__fadeInUp"
            : "opacity-0 translate-y-10"
        }`}
      >
        <p className="subcontenttitle text-center pt-4">
          Perfectcom ได้รับการรับรองมาตรฐาน ISO/IEC 29110
          และดำเนินการบริหารโครงการ (Project Management: PM)
          <br />
          รวมถึงการใช้งานซอฟต์แวร์ (Software Implementation: SI)
          ซึ่งเป็นไปตามข้อกำหนดของ ISO/IEC 29110-4-1:2018
        </p>
        <p className="subcontenttitle text-center">
          การได้รับการรับรองนี้ทำให้คุณมั่นใจในคุณภาพการผลิตและกระบวนการพัฒนาซอฟต์แวร์ที่มีมาตรฐานระดับสากล
          ซึ่งจะช่วยให้โครงการของคุณดำเนินไปอย่างราบรื่น <br />
          และมีประสิทธิภาพ นอกจากนี้ Perfectcom
          ยังได้รับการตรวจสอบและรับรองจากบริษัท ไอเซ็ม จำกัด (ISEM COMPANY
          LIMITED) <br />
          องค์กรรับรองระบบที่มีชื่อเสียงและได้รับการยอมรับอย่างสูงในประเทศไทย
        </p>
      </div>
    </div>
  );
}

export default Certificate;
