import React, { useState, useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "animate.css";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";

function Contact({ data }) {
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [adscontact, setAdscontact] = useState("");

  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    console.log("reCAPTCHA value:", value);
  };

  const contact = (event) => {
    event.preventDefault();

    if (!recaptchaValue) {
      console.log("Please complete the reCAPTCHA.");
      Swal.fire({
        title: "กรุณายืนยันตัวตนผ่าน Recaptcha",
        text: "",
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fname: name,
        lname: surname,
        email: email,
        header: title,
        des: description,
        ads: adscontact,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://api.perfectcomsolutions.com/contact/create",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          Swal.fire({
            title: "ส่งข้อมูลสำเร็จ",
            text: "",
            icon: "success",
            confirmButtonText: "ตกลง",
          });
          setName("");
          setSurname("");
          setEmail("");
          setTitle("");
          setDescription("");
          setAdscontact("");
          setRecaptchaValue(null);
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <div className="w-full py-16 sm:py-8">
      <div className="flex gap-3 flex-col w-full px-4">
        <form onSubmit={contact}>
          <div className="flex sm:flex-col md:flex-col sm:gap-6 justify-center items-center h-full gap-12 text-start pb-5">
            <div
              className={`flex justify-center items-center h-full w-full ${
                inView
                  ? "animate__animated animate__fadeInUp animate__slow"
                  : "opacity-0 translate-y-10"
              }`}
            >
              <img
                src="/contact-aw-section.png"
                className="w-[600px] h-full sm:w-full"
                alt="logo-client"
              />
            </div>
            <div
              ref={inViewRef}
              className={`w-full flex flex-col gap-2 ${
                inView
                  ? "animate__animated animate__fadeInUp animate__slow"
                  : "opacity-0 translate-y-10"
              }`}
            >
              <div className="flex flex-col sm:justify-center sm:items-center sm:text-center justify-end items-start w-fit">
                <p className="titlecontent">
                  {data && data.ContactSection && data.ContactSection.title}
                  <span className="text-warning">.</span>
                </p>
                <p className="subcontenttitle p-0 m-0">
                  {data &&
                    data.ContactSection &&
                    data.ContactSection.subcontent}
                </p>
              </div>
              <div className="w-full pt-2">
                <label htmlFor="your-name" className="form-label custom-label">
                  {data &&
                    data.ContactSection &&
                    data.ContactSection.inputfield[0].fname}
                  *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="your-name"
                  name="your-name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="your-surname"
                  className="form-label custom-label"
                >
                  {data &&
                    data.ContactSection &&
                    data.ContactSection.inputfield[1].lname}
                  *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="your-surname"
                  name="your-surname"
                  required
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </div>
              <div className="w-full">
                <label htmlFor="your-email" className="form-label custom-label">
                  {data &&
                    data.ContactSection &&
                    data.ContactSection.inputfield[2].email}
                  *
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="your-email"
                  name="your-email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="your-subject"
                  className="form-label custom-label"
                >
                  {data &&
                    data.ContactSection &&
                    data.ContactSection.inputfield[3].title}
                  *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="your-subject"
                  name="your-subject"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="your-message"
                  className="form-label custom-label"
                >
                  {data &&
                    data.ContactSection &&
                    data.ContactSection.inputfield[4].des}
                  *
                </label>
                <textarea
                  className="form-control"
                  id="your-message"
                  name="your-message"
                  rows="5"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="w-full">
                <label htmlFor="your-ads" className="form-label custom-label">
                  {data &&
                    data.ContactSection &&
                    data.ContactSection.inputfield[5].contact}
                  *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="your-ads"
                  name="your-ads"
                  placeholder={
                    data &&
                    data.ContactSection &&
                    data.ContactSection.inputfield[5].placeholder
                  }
                  required
                  value={adscontact}
                  onChange={(e) => setAdscontact(e.target.value)}
                />
              </div>
              <div className="w-full text-start">
                <ReCAPTCHA
                  sitekey="6LfvecYnAAAAAMlmMX4gwaEQxb8-2mBQkEgDXQdH"
                  onChange={handleRecaptchaChange}
                />
                <button
                  type="submit"
                  className="btn btn-outline-danger active rounded-pill px-3"
                  data-mdb-ripple-color="dark"
                >
                  {data.ContactSection.button}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
