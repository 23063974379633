import React, { useState } from "react";
import "./App.css";
import Navbar from "./component/navbar/navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Subhead from "./component/section/subhead";
import "font-awesome/css/font-awesome.min.css";
import About from "./component/section/about";
import Service from "./component/section/service";
import Product from "./component/section/product";
import Techstack from "./component/section/techstack";
import Ourclients from "./component/section/ourclients";
import Partner from "./component/section/partner";
import Blog from "./component/section/blog";
import Contact from "./component/section/contact";
import Footer from "./component/footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowUp, FaPhoneAlt } from "react-icons/fa";
import { faFacebook, faLine } from "@fortawesome/free-brands-svg-icons";
import Certificate from "./component/section/certificate";
import dataTH from "../src/utils/datalanguage/dataTH.json";
import dataEN from "../src/utils/datalanguage/dataEN.json";
import dataCN from "../src/utils/datalanguage/dataCN.json";
import Process from "./component/section/process";

function App() {
  let calcScrollValue = () => {
    let scrollProgress = document.getElementById("progress");
    let pos = document.documentElement.scrollTop;
    let calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    let scrollValue = Math.round((pos * 100) / calcHeight);
    if (pos > 100) {
      scrollProgress.style.display = "grid";
    } else {
      scrollProgress.style.display = "none";
    }
    scrollProgress.addEventListener("click", () => {
      document.documentElement.scrollTop = 0;
    });
    scrollProgress.style.background = `conic-gradient(#E55951 ${scrollValue}%, #d7d7d7 ${scrollValue}%)`;
  };
  window.onscroll = calcScrollValue;
  window.onload = calcScrollValue;

  const [data, setData] = useState(dataTH);
  const [language, setLanguage] = useState("TH");

  const changeLanguage = (langKey) => {
    switch (langKey) {
      case "TH":
        setData(dataTH);
        break;
      case "EN":
        setData(dataEN);
        break;
      case "CN":
        setData(dataCN);
        break;
      default:
        setData(dataTH);
    }
    setLanguage(langKey);
  };

  return (
    <div className="w-full bg-white">
      <div id="progress" style={{ zIndex: 100 }}>
        <span id="progress-value">
          <FaArrowUp />
        </span>
      </div>
      {/* <div class="shareit" style={{ zIndex: 100 }}>
        <a
          class="facebook"
          href="https://www.facebook.com/pfcsolutions"
          target="blank"
          title="facebook"
        >
          <FontAwesomeIcon icon={faFacebook} style={{ fontSize: "33px" }} />
        </a>
        <a class="phone" href="tel:0626496993" target="blank" title="Phone">
          <FaPhoneAlt style={{ fontSize: "28px" }} />
        </a>

        <a
          class="line"
          href="https://lin.ee/MqYiSFm"
          target="blank"
          title="Line"
        >
          <FontAwesomeIcon
            color="#fff"
            icon={faLine}
            style={{ fontSize: "29px" }}
          />
        </a>
      </div> */}
      <div className="w-full sticky top-0 z-[100000]">
        <Navbar data={data} changeLanguage={changeLanguage} />
      </div>
      <div id="home" className="section">
        <Subhead data={data} />
      </div>
      <div id="services" className="section">
        <Service data={data} />
      </div>
      <div id="process" className="section">
        <Process data={data} />
      </div>
      <div id="clients" className="section mt-4">
        <Ourclients data={data} />
      </div>
      <div id="products" className="section">
        <Product data={data} />
      </div>
      <Techstack data={data} />
      <Certificate data={data} />
      <div id="contact" className="section">
        <Contact data={data} />
      </div>
      <Footer data={data} />
    </div>
  );
}

export default App;
